import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "#app/components/utils"

const buttonVariants = cva(
  "opacity-100 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-brand text-primary-foreground hover:bg-brand/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        destructiveLight:
          "bg-destructive/15 text-destructive hover:bg-destructive/20",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        brandAccent: "bg-brand-accent text-primary-foreground hover:bg-brand-accent/90",
        brandSecondary: "bg-brand-background text-brand-secondary",
        menu: "bg-menu-accent rounded-full text-menu-primary disabled:opacity-70",
        'menu:outlined' : "border border-menu-accent rounded-full text-menu-primary disabled:opacity-70"
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8 text-base font-bold",
        icon: "h-10 w-10",
        iconSmall: "h-6 w-6"
      },
      width: {
        default: "",
        full: "w-full"
      },
      rounded: {
        no: "",
        full: "rounded-full"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      rounded: "no"
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  LeftIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  RightIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false,width,children,RightIcon,LeftIcon, rounded, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className, width, rounded }), RightIcon && 'justify-between')}
        ref={ref}
        {...props}
      >
        <>
        {LeftIcon ? <span className="mr-4">{<LeftIcon className="h-4 w-4 shrink-0 opacity-90" />}</span> : null}
        {children}
        {RightIcon ? <span className="ml-4">{<RightIcon className="h-4 w-4 shrink-0 opacity-90" />}</span> : null}
        </>
      </Comp>
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
